import React from 'react';

import styled from '@emotion/styled';

const StyledLoader = styled.div`
  height: 80px;
  width: 80px;
  position: relative;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
      border-width: 8px;
    }
    50% {
      transform: rotate(180deg);
      border-width: 1px;
    }
    100% {
      transform: rotate(360deg);
      border-width: 8px;
    }
  }
`;

const StyledSpin = styled.div`
  box-sizing: border-box;
  postion: absolute;
  width: 100%;
  height: 100%;
  border: 8px solid transparent;
  border-top-color: ${({ theme }) => theme.color.palette['base-blue']};
  border-bottom-color: ${({ theme }) => theme.color.palette['base-blue']};
  border-radius: 50%;
  animation: spinner 1.2s linear infinite;
`;

export const Loader: React.FC = () => {
  return (
    <StyledLoader>
      <StyledSpin />
    </StyledLoader>
  );
};
