import { graphql } from 'gatsby';
import React from 'react';

import styled from '@emotion/styled';
import { Loader } from '../atoms/loader';
import { CTA, CtaProps } from './cta';
import { EditorialContentThumbnail, EditorialContentThumbnailProps } from './editorial-content-thumbnail';

export type EditorialContentListProps = {
  className?: string;
  thumbnails: EditorialContentThumbnailProps[];
  cta?: CtaProps;
  showContentTags?: boolean;
  showLoadMore?: boolean;
  isLoading?: boolean;
  loadMore?: () => void;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin: 0 -45px 20px;
  }
`;

const ListWrapper = styled.div`
  align-items: baseline;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
  }
`;

const Tile = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin: 0 45px;
  }

  flex: 1 0 260px;
`;

const ThumbnailWrapper = styled(Tile)`
  display: flex;
  flex-direction: column;

  margin-top: 54px;
`;

const CTAWrapper = styled.div`
  margin-top: 36px;
  margin-bottom: 70px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-bottom: 0;
  }
`;

const StyledThumbnail = styled(EditorialContentThumbnail)``;

export const EditorialContentList: React.FC<EditorialContentListProps> = props => {
  return (
    <Wrapper className={props.className}>
      <ListWrapper>
        {props.thumbnails.map(thumbnailProps => {
          return (
            <ThumbnailWrapper key={thumbnailProps.title}>
              <StyledThumbnail {...thumbnailProps} showTags={props.showContentTags} />
            </ThumbnailWrapper>
          );
        })}

        {props.thumbnails.length % 3 === 2 && <Tile />}

        {props.thumbnails.length % 3 === 1 && (
          <>
            <Tile />
            <Tile />
          </>
        )}
      </ListWrapper>

      {props.showLoadMore && props.cta && !props.isLoading && (
        <CTAWrapper>
          <CTA {...props.cta} onClick={props.loadMore} />
        </CTAWrapper>
      )}
      {props.isLoading && <Loader />}
    </Wrapper>
  );
};

export const editorialContentListFragment = graphql`
  fragment ArticleList on cms_ComponentHomepageArticleList {
    __typename
    link {
      ...Link
    }
    text
    title
  }
`;
