import React from 'react';

import styled from '@emotion/styled';
import { TagList, TagListProps } from './tag-list';
import { Image, ImageProps } from '../atoms/image';
import { CTALink } from '../atoms/cta-link';
import { Heading } from '../atoms/heading';
import { useIntl } from 'gatsby-plugin-react-intl';
import { SmartLink } from '../atoms/smart-link';
import { Link as LinkType } from '../../types/links';
import { useIntlLink } from '../../hooks/useIntlLink';

export type EditorialContentThumbnailProps = {
  image?: ImageProps;
  title: string;
  excerpt: string;
  tags?: TagListProps['tags'];
  link?: LinkType;
  showTags?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  display: flex;
  position: relative;

  img {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  img:hover {
    opacity: 0.01;
    transition: opacity 300ms ease-out;
  }

  .fadeIn {
    opacity: 0.01;
    transition: opacity 300ms ease-out;
  }
  .fadeIn:hover {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 15px;
`;

const Excerpt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.color.palette['base-purple']};
  padding: 34px 30px 0 30px;

  p {
    overflow: hidden;
  }
  a {
    margin-bottom: 20px;
  }
`;

export const EditorialContentThumbnail: React.FC<EditorialContentThumbnailProps> = props => {
  const intl = useIntl();
  const readMore = intl.formatMessage({ id: 'read_more' });

  const { localizeLink } = useIntlLink();
  const link = localizeLink(props.link ?? '');

  return (
    <Wrapper>
      <ImageWrapper>
        <Image css={{ width: '100%', height: '100%' }} {...props.image} />

        <Excerpt className="fadeIn">
          {props.excerpt && <p>{props.excerpt}</p>}
          <CTALink text={readMore} link={link} color="link" underline="secondary" uppercase />
        </Excerpt>
      </ImageWrapper>

      <ContentWrapper>
        <SmartLink link={link}>
          <Heading level="h3" text={props.title} />
        </SmartLink>

        {props.showTags ? <TagList tags={props.tags} /> : null}
      </ContentWrapper>
    </Wrapper>
  );
};

EditorialContentThumbnail.defaultProps = {
  link: '#',
};
